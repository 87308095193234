import { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.css";
import { useParams, Outlet } from "react-router-dom";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Trajectory from "./trejectory";
import NotFound from "./notFound";

export default function GrenadeMap() {

    const [nades, setNades] = useState([]);
    const [filteredNades, setFilteredNades] = useState([])

    const { map } = useParams();

    const [loading, setLoading] = useState(1)

    useEffect(() => {
        async function getNades() {
            setLoading(0.8)
            const response = await fetch(`${process.env.REACT_APP_API_URL}nade/map/${map}`);

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }
      
            const nades = await response.json();
            setNades(nades)
            setLoading(1)
        }
      
        setNades([])
        getNades();
      
        return;
    }, [map]);

    const [smoke, setSmoke] = useState("active")
    const [flash, setFlash] = useState("active")
    const [fire, setFire] = useState("active")
    const [he, setHE] = useState("active")

    const [T, setT] = useState("")
    const [Both, setBoth] = useState("active")
    const [CT, setCT] = useState("")

    const [team, setTeam] = useState("all")
    const [player, setPlayer] = useState("all")
    const [sortType, setSortType] = useState("Most Thrown")

    const [maxNades, setMaxNades] = useState("100")

    const [tournaments, setTournaments] = useState({
        "BLAST World Final 2023": true,
        "BLAST Premier Fall Groups 2023": true,
        "IEM Sydney 2023": false,
        "Roobet Cup 2023": false,
        "Thunderpick World Championship 2023": false,
    })
    const [changed, setChanged] = useState(0)

    const [numShown, setNumShown] = useState(25)

    const [onlyShowEnd, setOnlyShowEnd] = useState(false)

    useEffect(() => {
        let tempNades = []

        // Remove nades from list that shouldn't appear based on filters
        for(let i = 0; i < nades.length; i++) {
            // If current grenade type is not active, continue
            if((nades[i].GrenadeType === "Flashbang" && flash !== "active") || 
               (nades[i].GrenadeType === "Smoke Grenade" && smoke !== "active") || 
               (nades[i].GrenadeType === "Fire Grenade" && fire !== "active") || 
               (nades[i].GrenadeType === "HE Grenade" && he !== "active") ||
               (nades[i].Throws.length < 3)) { continue; }

            let tempThrows = []

            // Filter out specific throws instances   
            for(let j = 0; j < nades[i]["Throws"].length; j++) {
                if((nades[i]["Throws"][j]["side"] === "t" && CT === "active") ||
                   (nades[i]["Throws"][j]["side"] === "ct" && T === "active") ||
                   (nades[i]["Throws"][j]["teamName"] !== team && team !== "all") || 
                   (nades[i]["Throws"][j]["playerName"] !== player && player !== "all") ||
                   (!tournaments[nades[i]["Throws"][j]["tournamentName"]])) { continue; }

                tempThrows.push(nades[i]["Throws"][j])
            }

            tempNades.push(structuredClone(nades[i]))
            tempNades[tempNades.length - 1]["Throws"] = tempThrows
        }

        if(sortType === "Most Thrown") {
            // Sort by the number of times thrown, after filters
            tempNades.sort(function(a, b){return b["Throws"].length - a["Throws"].length})

            if(tempNades.length > numShown) {
                tempNades = tempNades.slice(0, numShown)
            }
        } else if(sortType === "Random") {
            let m = tempNades.length;
            while (m) {
                const i = Math.floor(Math.random() * m--);
                [tempNades[m], tempNades[i]] = [tempNades[i], tempNades[m]];
            }

            tempNades = tempNades.slice(0, numShown)
        } else {
            // Sort by the number of times thrown, after filters
            tempNades.sort(function(a, b){return b["Throws"].length - a["Throws"].length})

            if(tempNades.length > numShown) {
                tempNades = tempNades.slice(0, numShown)
            }
        }

        setFilteredNades(tempNades)

        if(nades.length < 100) {
            setMaxNades(String(nades.length))
        } else {
            setMaxNades("100")
        }

    }, [nades, smoke, flash, fire, he, T, Both, CT, player, team, changed, tournaments, sortType, numShown])

    if(!["de_anubis", "de_ancient", "de_inferno", "de_mirage", "de_nuke", "de_overpass", "de_vertigo"].includes(map)) {
        return(<NotFound/>)
    }

    function toggleSmoke() {
        if(smoke === "active") {
            setSmoke("-")
        } else {
            setSmoke("active")
        }
    }

    function toggleFlash() {
        if(flash === "active") {
            setFlash("-")
        } else {
            setFlash("active")
        }
    }

    function toggleFire() {
        if(fire === "active") {
            setFire("-")
        } else {
            setFire("active")
        }
    }

    function toggleHE() {
        if(he === "active") {
            setHE("-")
        } else {
            setHE("active")
        }
    }

    function clickT() {
        setT("active")
        setBoth("")
        setCT("")
    }

    function clickBoth() {
        setT("")
        setBoth("active")
        setCT("")
    }

    function clickCT() {
        setT("")
        setBoth("")
        setCT("active")
    }

    function toggleTournament(tournament) {
        let tempTournaments = tournaments
        tempTournaments[tournament] = !tournaments[tournament]
        setTournaments(tempTournaments)
        setChanged((x) => (x + 1))
    }

    function nadesMap() {
        return filteredNades.map((nade) => {
            return(<Trajectory importedNade={{nade}} onlyShowEnd={{onlyShowEnd}}></Trajectory>)
        });
    }

    function teamsList() {
        let teams = []
        // Get list of all teams (without filters)
        for(let i = 0; i < nades.length; i++) {
            for(let j = 0; j < nades[i]["Throws"].length; j++) {
                if(teams.includes(nades[i]["Throws"][j]["teamName"]) || !tournaments[nades[i]["Throws"][j]["tournamentName"]]){ continue; }

                teams.push(nades[i]["Throws"][j]["teamName"])
            }
        }

        teams = teams.sort(function(a, b){return a > b})

        return teams.map((t) => {
            return (
                <option value={t} onClick={() => {setTeam(t); setPlayer("all")}}>{t}</option>
            )
        })
    }

    function playersList() {
        let players = []
        // Get list of all players after filters
        for(let i = 0; i < nades.length; i++) {
            for(let j = 0; j < nades[i]["Throws"].length; j++) {
                if(players.includes(nades[i]["Throws"][j]["playerName"]) || !tournaments[nades[i]["Throws"][j]["tournamentName"]]){ continue; }
                if((nades[i]["Throws"][j]["teamName"] === team || team === "all")) {
                    players.push(nades[i]["Throws"][j]["playerName"])
                }
            }
        }

        players = players.sort(function(a, b){return a > b})

        return players.map((p) => {
            return (
                <option value={p} onClick={() => {setPlayer(p)}}>{p}</option>
            )
        })
    }

    function toggleTrajectories() {
        setOnlyShowEnd(!onlyShowEnd)
    }

    let loadingSpinner = <></>

    if(loading === 0.8) {
        loadingSpinner = 
        <div className="spinner-grow text-light" role="status" style={{position: 'absolute', top: 45 + '%', left: 45 + '%', height: 10 + '%', width: 10 + '%'}}>
            <span className="visually-hidden">Loading...</span>
        </div>
    }

    document.title = "CS Pro Nades - " + map.charAt(3).toUpperCase() + map.substring(4)

    return (
        <div style={{display: 'flex', flexDirection: 'row', backgroundColor: "white", paddingTop: 60}}>
            <div className="shadow-sm scroll" style={{overflowY: 'scroll', alignItems: 'stretch', position: 'relative', width: 'calc((100vw - 100vh + 60px - 2vw) / 2)', height: 'calc(100vh - 60px - 2vw)', margin: 1 + 'vw', backgroundColor: 'lightgray', borderRadius: 10, border: '1px solid rgba(0, 0, 0, .2)'}}>
                <h3 style={{padding: 1 + 'vw', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}>Filters</h3>
                <div>
                    <div style={{paddingLeft: 1 + 'vw', paddingRight: 1 + 'vw', paddingBottom: 1 + 'vw', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}>
                        <h5>Grenade Types:</h5>
                        <button type="button" className={"btn btn-md shadow-sm btn-outline-secondary " + smoke} onClick={() => toggleSmoke()} style={{margin: 2}}>
                            Smokes 
                            <span class="badge rounded-pill text-bg-info" style={{marginLeft: 5}}>{nades.filter((obj) => obj.GrenadeType === "Smoke Grenade").length}</span>
                        </button>
                        <button type="button" className={"btn btn-md shadow-sm btn-outline-warning text-dark " + flash} onClick={() => toggleFlash()} style={{margin: 2}}>
                            Flashes
                            <span class="badge rounded-pill text-bg-info" style={{marginLeft: 5}}>{nades.filter((obj) => obj.GrenadeType === "Flashbang").length}</span>
                        </button>
                        <button type="button" className={"btn btn-md shadow-sm btn-outline-danger " + fire} onClick={() => toggleFire()} style={{margin: 2}}>
                            Moltovs
                            <span class="badge rounded-pill text-bg-info" style={{marginLeft: 5}}>{nades.filter((obj) => obj.GrenadeType === "Fire Grenade").length}</span>
                        </button>
                        <button type="button" className={"btn btn-md shadow-sm btn-outline-success " + he} onClick={() => toggleHE()} style={{margin: 2}}>
                            HE Grenades
                            <span class="badge rounded-pill text-bg-info" style={{marginLeft: 5}}>{nades.filter((obj) => obj.GrenadeType === "HE Grenade").length}</span>
                        </button>
                    </div>
                    <div style={{padding: 1 + 'vw', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}>
                        <h5 style={{display: 'inline-block'}}>Side:</h5>
                        <div className="btn-group shadow-sm" role="group" aria-label="Basic outlined example" style={{display: 'inline-block', marginLeft: 10}}>
                            <button type="button" className={"btn btn-outline-dark " + T} onClick={() => clickT()} style={{ width: 80, borderWidth: 1 }}>
                                T
                                <span class="badge rounded-pill text-bg-info" style={{marginLeft: 5}}>{nades.filter((obj) => obj.Side === "t").length}</span>
                            </button>
                            <button type="button" className={"btn btn-outline-dark " + Both} onClick={() => clickBoth()} style={{ borderWidth: 1}}>Both</button>
                            <button type="button" className={"btn btn-outline-dark " + CT} onClick={() => clickCT()} style={{ width: 80, borderWidth: 1}}>
                                CT
                                <span class="badge rounded-pill text-bg-info" style={{marginLeft: 5}}>{nades.filter((obj) => obj.Side === "ct").length}</span>
                            </button>
                        </div>
                    </div>
                    <div style={{paddingLeft: 1 + 'vw', paddingRight: 1 + 'vw', paddingBottom: 1 + 'vw', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}>
                        <h5 style={{marginTop: 10}}>Tournaments:</h5>
                        <div className="scroll shadow-sm" style={{height: 120, overflowY: 'scroll', backgroundColor: 'white', borderRadius: 5}}>
                            <div className="form-check" style={{marginLeft: 6, marginTop: 2}}>
                                <input className="form-check-input shadow-sm" type="checkbox" id="flexSwitchCheckChecked" checked={tournaments["BLAST World Final 2023"]} onClick={() => {toggleTournament("BLAST World Final 2023")}}/>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                    BLAST World Final 2023
                                    <span class="badge rounded-pill text-bg-info" style={{marginLeft: 5}}>{nades.filter((obj) => obj.Throws.filter((obj) => obj.tournamentName === "BLAST World Final 2023").length > 0).length}</span>
                                </label>
                            </div>
                            <div className="form-check" style={{marginLeft: 6, marginTop: 2}}>
                                <input className="form-check-input shadow-sm" type="checkbox" id="flexSwitchCheckChecked" checked={tournaments["BLAST Premier Fall Groups 2023"]} onClick={() => {toggleTournament("BLAST Premier Fall Groups 2023")}}/>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                    BLAST Premier Fall Groups 2023
                                    <span class="badge rounded-pill text-bg-info" style={{marginLeft: 5}}>{nades.filter((obj) => obj.Throws.filter((obj) => obj.tournamentName === "BLAST Premier Fall Groups 2023").length > 0).length}</span>
                                </label>
                            </div>
                            <div className="form-check" style={{marginLeft: 6, marginTop: 2}}>
                                <input className="form-check-input shadow-sm" type="checkbox" id="flexSwitchCheckChecked" checked={tournaments["Thunderpick World Championship 2023"]} onClick={() => {toggleTournament("Thunderpick World Championship 2023")}}/>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                    Thunderpick World Championship 2023
                                    <span class="badge rounded-pill text-bg-info" style={{marginLeft: 5}}>{nades.filter((obj) => obj.Throws.filter((obj) => obj.tournamentName === "Thunderpick World Championship 2023").length > 0).length}</span>
                                </label>
                            </div>
                            <div className="form-check" style={{marginLeft: 6, marginTop: 2}}>
                                <input className="form-check-input shadow-sm" type="checkbox" id="flexSwitchCheckChecked" checked={tournaments["Roobet Cup 2023"]} onClick={() => {toggleTournament("Roobet Cup 2023")}}/>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                    Roobet Cup 2023
                                    <span class="badge rounded-pill text-bg-info" style={{marginLeft: 5}}>{nades.filter((obj) => obj.Throws.filter((obj) => obj.tournamentName === "Roobet Cup 2023").length > 0).length}</span>
                                </label>
                            </div>
                            <div className="form-check" style={{marginLeft: 6, marginTop: 2}}>
                                <input className="form-check-input shadow-sm" type="checkbox" id="flexSwitchCheckChecked" checked={tournaments["IEM Sydney 2023"]} onClick={() => {toggleTournament("IEM Sydney 2023")}}/>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                    IEM Sydney 2023
                                    <span class="badge rounded-pill text-bg-info" style={{marginLeft: 5}}>{nades.filter((obj) => obj.Throws.filter((obj) => obj.tournamentName === "IEM Sydney 2023").length > 0).length}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div style={{paddingLeft: 1 + 'vw', paddingRight: 1 + 'vw', paddingBottom: 1 + 'vw', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}>
                        <div style={{display: 'inline-block', width: 'calc(50% - 0.5vw)', marginRight: 1 + 'vw'}}>
                            <h5 style={{display: 'inline-block'}}>Team: </h5>
                            <select className="form-select shadow-sm" value={team} style={{display: 'inline-block'}}>
                                <option selected onClick={() => {setTeam("all"); setPlayer("all")}}>All</option>
                                {teamsList()}
                            </select>
                        </div>
                        <div style={{display: 'inline-block', width: 'calc(50% - 0.5vw)'}}>
                            <h5 style={{marginTop: 10, display: 'inline-block'}}>Player:</h5>
                            <select className="form-select shadow-sm" value={player} style={{display: 'inline-block'}}>
                                <option selected onClick={() => {setPlayer("all")}}>All</option>
                                {playersList()}
                            </select>
                        </div>
                    </div>
                    <div style={{paddingLeft: 1 + 'vw', paddingRight: 1 + 'vw', paddingBottom: 1 + 'vw', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}>
                    <h5 style={{marginTop: 10}}>Showing {filteredNades.length} of {nades.length} grenades:</h5>
                        <input type="range" className="form-range" min="1" max={maxNades} id="customRange1" value={numShown} onChange={(e) => setNumShown(e.target.value)}></input>
                        <select className="form-select shadow-sm" value={sortType}>
                            <option selected onClick={() => {setSortType("Most Thrown")}}>Most Thrown</option>
                            <option onClick={() => {setSortType("Random")}}>Random</option>
                        </select>
                    </div>
                    <div style={{paddingLeft: 1 + 'vw', paddingRight: 1 + 'vw', paddingBottom: 1 + 'vw'}}>
                        <div className="form-check" style={{marginTop: 10, marginLeft: 4, paddingLeft: 1 + 'vw', paddingRight: 1 + 'vw', paddingBottom: 1 + 'vw'}}>
                            <input className="form-check-input shadow-sm" type="checkbox" id="flexSwitchCheckChecked" checked={!onlyShowEnd} onClick={() => {toggleTrajectories()}}/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"><h5>Show Trajectories</h5></label>
                        </div>
                    </div>
                </div>
            </div>
                <div style={{alignItems: 'stretch', position: 'relative', height: 'calc(100vh - 60px - 2vw)', marginTop: 1 + 'vw', marginBotton: 1 + 'vw', borderRadius: 20}}>
                    <TransformWrapper>
                        <TransformComponent>
                            <img src={require("../images/".concat(map, '.png'))} 
                                alt=""
                                style={{width: 'calc(100vh - 60px - 2vw)', 
                                        height: 'calc(100vh - 60px - 2vw)', 
                                        display: 'inline-block',
                                        borderRadius: 20,
                                        opacity: loading}}/>
                            {nadesMap()}
                            {loadingSpinner}
                        </TransformComponent>
                    </TransformWrapper>
                </div>
            <Outlet context={[T, Both, CT, team, player, tournaments, changed]}/>
        </div>
    )
}
