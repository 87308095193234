import React from "react";
 
// We use Route in order to define the different routes of our application
import { Route, Routes } from "react-router-dom";
 
// We import all the components we need in our app
import Navbar from "./components/navbar";
import GrenadeMap from "./components/grenadeMap";
import Home from "./components/home";
import GrenadeInfo from "./components/grenadeInfo";
import Footer from "./components/footer";
import NotFound from "./components/notFound";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
 
const App = () => {
 return (
   <div>
     <Navbar />
     <Routes>
       <Route exact path="/" element={<Home />} />
       <Route path="/map/:map" element={<GrenadeMap />} >
          <Route path=":id" element={<GrenadeInfo />} />
       </Route>
       <Route path="*" element={<NotFound />} />
     </Routes>
     <Footer />
   </div>
 );
};
 
export default App;