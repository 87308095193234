import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useOutletContext } from "react-router-dom";

export default function GrenadeInfo() {
    const tempNade = {
        "_id":"0","ThrowPosition":{"x":0,"y":0,"z":0,"vx":0,"vy":0},"Path":[],"AirTime":0,"MapName":"","GrenadeType":"","Throws":[]
    }
    const [nade, setNade] = useState(tempNade)
    const [setpos, setSetpos] = useState()
    const [filteredThrows, setFilteredThrows] = useState([])
    const nadeID = useParams()["id"]

    const [T, Both, CT, team, player, tournaments, changed] = useOutletContext()

    const [crouching, setCrouching] = useState("")
    const [jumping, setJumping] = useState("")

    const [loading, setLoading] = useState(true)

    const [divColor, setDivColor] = useState('lightgray')

    useEffect(() => {
        if(Object.keys(nade).length === 0) {
            setDivColor('lightgray')
        } else if(nade.GrenadeType === "Flashbang") {
            setDivColor('#eed984')
        } else if(nade.GrenadeType === "Smoke Grenade") {
            setDivColor('lightgray')
        } else if(nade.GrenadeType === "Fire Grenade") {
            setDivColor('#ee9984')
        } else if(nade.GrenadeType === "HE Grenade") {
            setDivColor('#a4d184')
        } else {
            setDivColor('lightgray')
        }
    }, [nade])

    useEffect(() => {
        async function getNade() {
            const response = await fetch(`${process.env.REACT_APP_API_URL}nade/id/${nadeID}`)

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }

            const n = await response.json();
            setNade(n);
        }

        
        setLoading(true)
        getNade();
        setLoading(false)


        if(Object.keys(nade).length !== 0) {
            if(nade.JumpThrow) {
                setJumping("Jump")
            } else {
                setJumping("No Jump")
            }

            if(nade.CrouchThrow) {
                setCrouching("Crouch")
            } else {
                setCrouching("Stand")
            }
        }
        
      
        return;
    }, [nadeID, nade.CrouchThrow, nade.JumpThrow]);

    useEffect(() => {
        if(Object.keys(nade).length !== 0) {
            const setpos = "setpos " + nade.ThrowPosition["x"].toFixed(6) + " " + nade.ThrowPosition["y"].toFixed(6) + " " + nade.ThrowPosition["z"].toFixed(6) + "; setang " + nade.ThrowPosition["vy"].toFixed(6) + " " + nade.ThrowPosition["vx"].toFixed(6) + " 0.000000"
            setSetpos(setpos)
        }
    }, [nade])
 
    useEffect(() => {
        if(Object.keys(nade).length !== 0) {
            let tempThrows = []

            for(let i = 0; i < nade["Throws"].length; i++) {
                if((nade["Throws"][i]["side"] === "t" && CT === "active") ||
                (nade["Throws"][i]["side"] === "ct" && T === "active") ||
                (nade["Throws"][i]["teamName"] !== team  && team !== "all") ||
                (nade["Throws"][i]["playerName"] !== player  && player !== "all") ||
                (!tournaments[nade["Throws"][i]["tournamentName"]])) { continue; }

                tempThrows.push(nade["Throws"][i])
            }

            setFilteredThrows(tempThrows)
        }

    }, [nade, T, Both, CT, team, player, tournaments, changed])

    function throwStats() {
        let stats = []
        let teams = []
        let totalThrown = 0

        let airTime = <></>
        let flashStats = <></>
        let damageStats = <></>
        let timeLeft = <></>

        let averageFlashed = 0.0
        let averageFlashTime = 0.0
        let averageDamage = 0.0
        let averageKills = 0.0
        let averageTimeLeft = 0.0

        if(nade["GrenadeType"] === "Smoke Grenade") {
            airTime = 
            <tr>
                <td>Air Time</td>
                <td>{nade["AirTime"].toFixed(2)}s</td>
            </tr>
            // <p>Air time: {nade["airTime"].toFixed(2)}s</p>
        }

        for(let i = 0; i < filteredThrows.length; i++) {
            if(!(teams.includes(filteredThrows[i].teamName))) {
                stats.push({"team": filteredThrows[i].teamName, "count": 1})
                teams.push(filteredThrows[i].teamName)
            } else {
                stats[teams.indexOf(filteredThrows[i].teamName)]["count"] += 1
            }

            averageDamage += filteredThrows[i].damage
            averageKills += filteredThrows[i].kills

            averageFlashed += filteredThrows[i].playersFlashed
            averageFlashTime += filteredThrows[i].flashTime

            averageTimeLeft += filteredThrows[i].timeLeft

            totalThrown += 1
        }

        averageDamage = averageDamage / totalThrown
        averageKills = averageKills / totalThrown

        averageFlashed = averageFlashed / totalThrown
        averageFlashTime = averageFlashTime / (totalThrown * averageFlashed)

        averageTimeLeft = averageTimeLeft / totalThrown

        let averageTimeLeftString = ""

        if(Math.floor(averageTimeLeft % 60) > 9) {
            averageTimeLeftString = Math.floor(averageTimeLeft / 60) + ":" + Math.floor(averageTimeLeft % 60)
        } else {
            averageTimeLeftString = Math.floor(averageTimeLeft / 60) + ":0" + Math.floor(averageTimeLeft % 60)
        }

        timeLeft = 
        <tr>
            <td>Average Throw Clock Time</td>
            <td>{averageTimeLeftString}</td>
        </tr>

        if(nade["GrenadeType"] === "Flashbang") {
            flashStats = 
            [
                <tr>
                    <td>Average Enemies Flashed</td>
                    <td>{averageFlashed.toFixed(2)}</td>
                </tr>,
                <tr>
                    <td>Average Enemy Flash Duration</td>
                    <td>{averageFlashTime.toFixed(2)}s</td>
                </tr>
            ]
        } else if(nade["GrenadeType"] === "HE Grenade" || nade["GrenadeType"] === "Fire Grenade") {
            damageStats = 
            [
                <tr>
                    <td>Average Damage</td>
                    <td>{averageDamage.toFixed(2)}</td>
                </tr>,
                <tr>
                    <td>Average Kills</td>
                    <td>{averageKills.toFixed(2)}</td>
                </tr>
            ]
        }

        totalThrown = 
        <tr>
            <td>Total Thrown</td>
            <td>{totalThrown}</td>
        </tr>

        stats = stats.sort(function(a, b){return b.count - a.count})

        let rowObjects = []

        for(let i = 0; i < stats.length; i++) {
            rowObjects.push(
                <tr>
                    <td>{stats[i]["team"]}</td>
                    <td>{stats[i]["count"]}</td>
                </tr>
            )
        }

        return (
            <div style={{paddingLeft: 1 + 'vw', paddingRight: 1 + 'vw', paddingTop: 10}}>
                <h5>{nade.GrenadeType} Stats:</h5>
                <div class="accordion shadow-sm" id="accordionExample" style={{marginTop: 10}}>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Stats
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body" style={{padding: 0}}>
                                <table class="table table-borderless table-hover">
                                    <tbody>
                                        {totalThrown}
                                        {timeLeft}
                                        {flashStats}
                                        {damageStats}
                                        {airTime}
                                    </tbody>
                                </table>
                                {/* <ul className="list-group list-group-flush">
                                    {totalThrown}
                                    {timeLeft}
                                    {flashStats}
                                    {damageStats}
                                    {airTime}
                                </ul> */}
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Number Thrown Per Team
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body" style={{padding: 0, margin: 0}}>
                                <div class="scroll" style={{height: 20 + 'vh', overflowY: 'scroll'}}>
                                    <table class="table table-borderless table-hover">
                                        {/* <thead>
                                            <tr>
                                                <th scope="col" >Team</th>
                                                <th scope="col" >Number Thrown</th>
                                            </tr>
                                        </thead> */}
                                        <tbody>
                                            {rowObjects}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if(Object.keys(nade).length === 0) {
        return(
            <div class="shadow-sm" style={{alignItems: 'stretch', position: 'relative', width: 'calc((100vw - 100vh + 60px - 2vw) / 2)', height: 'calc(100vh - 60px - 2vw)', margin: 1 + 'vw', backgroundColor: divColor, borderRadius: 10}}>
                <div style={{margin: 1 + 'vw'}}>
                    <h5>Invalid nade ID!</h5>
                </div>
            </div>
        )
    }

    if(loading) {
        return (
            <div style={{alignItems: 'stretch', position: 'relative', width: 28 + '%', height: 40 + 'vw', margin: 1 + 'vw', backgroundColor: divColor, borderRadius: 10}} >
                <div class="spinner-grow" role="status" style={{position: 'absolute', top: 50 + '%', left: 50 + '%', transform: 'translate(-50%, -50%'}}>
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div class="shadow-sm scroll" style={{border: '1px solid rgba(0, 0, 0, .2)', overflowY: 'scroll', alignItems: 'stretch', position: 'relative', width: 'calc((100vw - 100vh + 60px - 2vw) / 2)', height: 'calc(100vh - 60px - 2vw)', margin: 1 + 'vw', backgroundColor: divColor, borderRadius: 10}}>
                <h3 style={{padding: 1 + 'vw', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}>Grenade Info</h3>
                <div>
                    <div style={{paddingLeft: 1 + 'vw', paddingRight: 1 + 'vw', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}>
                        <h5>Throw Position:</h5>
                        <div>
                            <button className="font-monospace input-group-text shadow-sm" style={{whiteSpace: 'normal'}} onClick={() => {navigator.clipboard.writeText(`${setpos}`)}}>{setpos}</button>
                        </div>
                        <p>Click to Copy</p>
                    </div>
                    <div style={{paddingLeft: 1 + 'vw', paddingRight: 1 + 'vw', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}>
                        <h5 style={{marginTop: 10}}>Usage info:</h5>
                        <div className="btn-group">
                            <button className="font-monospace input-group-text fw-bold shadow-sm" disabled>{jumping}</button>
                            <button className="font-monospace input-group-text fw-bold shadow-sm" style={{marginLeft: 5}} disabled>{crouching}</button>
                        </div>
                        <p style={{marginTop: 5}}>Currently, movement (WASD), and left / middle / right click throws are not tracked.</p>
                    </div>
                    {throwStats()}
                </div>
            </div>
        )
    }
}