import React from "react";
import '../main.css';
 
// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css";
 
// We import NavLink to utilize the react router.
import { NavLink } from "react-router-dom";
 
// Here, we display our Navbar
export default function Navbar() {
 return (
   <div>
     <nav className="shadow-sm navbar fixed-top navbar-expand-lg navbar-light bg-light" style={{height: 60}}>
       <NavLink className="text-dark" to="/" style={{position: 'absolute', marginLeft: 1 + 'vw', top: 50 + '%', transform: "translate(0px, -50%)"}}>
        <div className="progress-stacked">
          <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: 5 + 'vw'}}>
            <div className="progress-bar"></div>
          </div>
          <div className="progress" role="progressbar" aria-label="Segment two" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" style={{width: 7 + 'vw'}}>
            <div className="progress-bar bg-danger"></div>
          </div>
        </div>
       </NavLink>
       <button
         className="navbar-toggler"
         type="button"
         data-toggle="collapse"
         data-target="#navbarSupportedContent"
         aria-controls="navbarSupportedContent"
         aria-expanded="false"
         aria-label="Toggle navigation"
       >
         <span className="navbar-toggler-icon"></span>
       </button>
 
       <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{position: 'absolute', left: 50 + '%', transform: 'translate(-50%, 0)'}}>
         <ul className="navbar-nav nav-underline">
           <li className="nav-item active-color-dark" style={{paddingLeft: 15}}>
             <NavLink className="nav-link" to="/map/de_ancient">
               Ancient
             </NavLink>
           </li>
           <li className="nav-item" style={{paddingLeft: 15, borderLeft: '1px solid lightgray'}}>
             <NavLink className="nav-link" to="/map/de_anubis">
               Anubis
             </NavLink>
           </li>
           <li className="nav-item" style={{paddingLeft: 15, borderLeft: '1px solid lightgray'}}>
             <NavLink className="nav-link" to="/map/de_inferno">
               Inferno
             </NavLink>
           </li>
           <li className="nav-item" style={{paddingLeft: 15, borderLeft: '1px solid lightgray'}}>
             <NavLink className="nav-link" to="/map/de_mirage">
               Mirage
             </NavLink>
           </li>
           <li className="nav-item" style={{paddingLeft: 15, borderLeft: '1px solid lightgray'}}>
             <NavLink className="nav-link" to="/map/de_nuke">
               Nuke
             </NavLink>
           </li>
           <li className="nav-item" style={{paddingLeft: 15, borderLeft: '1px solid lightgray'}}>
             <NavLink className="nav-link" to="/map/de_overpass">
               Overpass
             </NavLink>
           </li>
           <li className="nav-item" style={{paddingLeft: 15, borderLeft: '1px solid lightgray'}}>
             <NavLink className="nav-link" to="/map/de_vertigo">
               Vertigo
             </NavLink>
           </li>
         </ul>
       </div>
     </nav>
   </div>
 );
}