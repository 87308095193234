import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"
import { BrowserRouter } from "react-router-dom";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import ReactGA from "react-ga4"


if(process.env.REACT_APP_NODE_ENV === 'production') disableReactDevTools()

ReactGA.initialize("G-CCHK2L0CN3");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);