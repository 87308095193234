export default function Home () {
    document.title = "CS Pro Nades"

    return (
        <div style={{width: 'calc(50px + 40vw)', left: 'calc((100vw - 50px - 40vw) / 2)', position: 'relative', paddingTop: 60}}>
            <div style={{flexDirection: 'row', display: 'flex', marginTop: 50}}>
                <img src={require("../images/CSLogo.png")} style={{height: 100, borderRadius: 20, marginRight: 20}} alt=""/> 
                <h1 className="display-1">Pro Nades</h1>
            </div>
            <p className="lead" style={{borderBottom: '4px solid black', paddingBottom: 50 + 'px', marginTop: 20}}>Common nades thrown by professional counter strike players, captured from S-Tier tournament demos</p>
            <h3 className="display-6" style={{marginTop: 30 + 'px'}}>Criteria:</h3>
            <p className="lead">'Common' grenades are found by comparing all thrown grenades from all tournament demos to one another. Grenades are 
            clustered using a DBSCAN algorithm, with distance between nades being based on player throw position, player view angle, and final grenade position.</p>
            <p className="lead" style={{marginTop: 20}}>Each map shows a certain number of grenades thrown on each map. Filters can be used to refine what nades are thrown. By default, the most thrown nades are shown.</p>
            <h3 className="display-6" style={{marginTop: 30 + 'px'}}>Usage:</h3>
            <p className="lead">Click on a grenade trajectory to display more information about it.</p>
            <p className="lead">setpos / setang commands can be copied from the setpos text block.</p>
            <p className="lead">Grenades must be learned using the setpos / setang commands in a practice server with sv_cheats enabled.</p>
            <h3 className="display-6" style={{marginTop: 30 + 'px'}}>Planned Updates:</h3>
            <p className="lead">Track player movement direction and velocity when nades are thrown</p>
            <p className="lead">Track overall grenade throw frequency (throws per rounds), and frequency for specific teams / players</p>
            <p className="lead">Long term, automatically generating lineup screenshots from in game may be explored. It may also be interesting to incorperate a demo viewer which can display the context in which the grenades are thrown.</p>
            <p className="lead" style={{marginBottom: 50 + 'px'}}>If you have any ideas for additional features, please DM me on discord using the contact button in the footer!</p>
        </div>
    )
}