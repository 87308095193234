import { Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"

export default function Trajectory({ importedNade, onlyShowEnd }) {

    const { map, id } = useParams()

    const mapData = {
        "de_anubis": {
            "pos_x": -2796.0,
            "pos_y": 3328.0,
            "scale": 5.22
        },
        "de_overpass": {
            "pos_x": -4831.0,
            "pos_y": 1781.0,
            "scale": 5.2
        },
        "de_mirage": {
            "pos_x": -3230.0,
            "pos_y": 1713.0,
            "scale": 5.0
        },
        "de_nuke": {
            "pos_x": -3453.0,
            "pos_y": 2887.0,
            "scale": 7.0,
            "z_cutoff": -495
        },
        "de_ancient": {
            "pos_x": -2953.0,
            "pos_y": 2164.0,
            "scale": 5.0
        },
        "de_vertigo": {
            "pos_x": -3168.0,
            "pos_y": 1762.0,
            "scale": 4.0,
            "z_cutoff": 11700
        },
        "de_inferno": {
            "pos_x": -2087.0,
            "pos_y": 3870.0,
            "scale": 4.9
        },
    }

    const [height, setHeight] = useState(.3 + '%')
    const [zIndex, setZIndex] = useState('')
    const [top, setTop] = useState(.15)
    const [border, setBorder] = useState('')
    const [hovering, setHovering] = useState(false)

    useEffect(() => {
        setHeight(.3 + '%')
        setZIndex('')
        setTop(.15)
        setBorder('')
    }, [id])

    // if(id === importedNade.nade.id) {
    //     setHeight(.6 + '%')
    //     setZIndex(100)
    //     setTop(.3)
    //     setBorder('0.5px solid white')
    // } 

    function startHover() {
        setHeight(.6 + '%')
        setZIndex(100)
        setTop(.3)
        setBorder('0.5px solid white')
        setHovering(true)
    }

    function endHover() {
        if(id === importedNade.nade._id) {
            setHeight(.6 + '%')
            setZIndex(100)
            setTop(.3)
            setBorder('0.5px solid white')
        } else {
            setHeight(.3 + '%')
            setZIndex('')
            setTop(.15)
            setBorder('')
        }
        setHovering(false)
    }

    let nade = importedNade.nade

    if(nade.Throws.length === 0) {
        return(null)
    }

    let nadePaths = []

    if(nade.Path.length !== 1) {
        for(let i = 0; i < nade.Path.length - 1; i++) {
            nadePaths.push({x1: nade.Path[i]["x"], y1: nade.Path[i]["y"], z1: nade.Path[i]["z"], x2: nade.Path[i + 1]["x"], y2: nade.Path[i + 1]["y"], z2: nade.Path[i + 1]["z"]})
        }
    }

    let nadePathElements = nadePaths.map((path) => {
        let xCoord = 100 * (path["x1"] - mapData[nade.MapName]["pos_x"]) / (mapData[nade.MapName]["scale"] * 1024) - 0;
        let yCoord = 100 * (path["y1"] - mapData[nade.MapName]["pos_y"]) / (mapData[nade.MapName]["scale"] * 1024) + 0;
        let endXCoord = 100 * (path["x2"] - mapData[nade.MapName]["pos_x"]) / (mapData[nade.MapName]["scale"] * 1024) - 0;
        let endYCoord = 100 * (path["y2"] - mapData[nade.MapName]["pos_y"]) / (mapData[nade.MapName]["scale"] * 1024) + 0;
        let length = Math.sqrt(Math.pow(xCoord - endXCoord, 2) + Math.pow(yCoord - endYCoord, 2))
        let angle = Math.acos((endXCoord - xCoord) / (length))
        
        if(yCoord - endYCoord < 0) {
            angle = -angle 
        } 

        let color = "white"

        if(nade.GrenadeType === "Smoke Grenade") {
            color = "white"
        } else if (nade.GrenadeType === "Flashbang") {
            color = "yellow"
        } else if (nade.GrenadeType === "Fire Grenade") {
            color = "red"
        } else {
            color = "Chartreuse"
        }

        return(
            <div style={{position: 'absolute', 
                         top: -yCoord - top + '%', 
                         left: xCoord + '%', 
                         backgroundColor: color,
                         height: height,
                         width: length + '%', 
                         transform: 'rotate(' + angle + 'rad)', 
                         transformOrigin: 'left',
                         zIndex: zIndex,
                         borderRadius: 2 + 'px',
                         border: border,
                         opacity: 0.8
                        }} 
                onMouseEnter={() => startHover()}
                onMouseLeave={() => endHover()}/>
        )
    })

    if(onlyShowEnd.onlyShowEnd && !hovering && !(id === importedNade.nade._id)) {
        nadePathElements = <></>
    }
    
    let xCoord = 100 * (nade.Path[0]["x"] - mapData[nade.MapName]["pos_x"]) / (mapData[nade.MapName]["scale"] * 1024) - 0.25;
    let yCoord = 100 * (nade.Path[0]["y"] - mapData[nade.MapName]["pos_y"]) / (mapData[nade.MapName]["scale"] * 1024) + 0.25;

    let endXCoord = 100 * (nade.Path[nade.Path.length - 1]["x"] - mapData[nade.MapName]["pos_x"]) / (mapData[nade.MapName]["scale"] * 1024) - 0.5;
    let endYCoord = 100 * (nade.Path[nade.Path.length - 1]["y"] - mapData[nade.MapName]["pos_y"]) / (mapData[nade.MapName]["scale"] * 1024) + 0.5;

    let color = "white"

    if(nade.GrenadeType === "Smoke Grenade") {
        color = "white"
    } else if (nade.GrenadeType === "Flashbang") {
        color = "yellow"
    } else if (nade.GrenadeType === "Fire Grenade") {
        color = "red"
    } else {
        color = "Chartreuse"
    }
    
    return (
        <div>
            <Link to={"/map/".concat(map, "/", nade._id)}>
                {nadePathElements}
                <div style={{position: 'absolute', 
                            top: -yCoord + '%', 
                            left: xCoord + '%', 
                            height: .5 + '%', 
                            width: .5 + '%',
                            borderRadius: 50 + '%',
                            backgroundColor: color,
                            zIndex: zIndex, 
                            border: border}}
                    onMouseEnter={() => startHover()}
                    onMouseLeave={() => endHover()}/>
                <div style={{position: 'absolute', 
                            top: -endYCoord + '%', 
                            left: endXCoord + '%', 
                            height: 1 + '%', 
                            width: 1 + '%',
                            borderRadius: 50 + '%',
                            backgroundColor: color,
                            zIndex: zIndex, 
                            border: border}}
                    onMouseEnter={() => startHover()}
                    onMouseLeave={() => endHover()}/>
            </Link>
        </div>
    )
}